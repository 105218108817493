import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NRF25 from './pages/NRF25/index';
import Overlay1 from './pages/NRF25/overlays/NRF1';
import Overlay2 from './pages/NRF25/overlays/NRF2';
import Overlay3 from './pages/NRF25/overlays/NRF3';
import Overlay4 from './pages/NRF25/overlays/NRF4';

function App() {
  const [step, setStep] = useState(1);

  const renderOverlay = () => {
    switch (step) {
      case 1:
        return <Overlay1 setStep={setStep} />;
      case 2:
        return <Overlay2 setStep={setStep} />;
      case 3:
        return <Overlay3 setStep={setStep} />;
      case 4:
        return <Overlay4 setStep={setStep} />;
      default:
        return <Overlay1 setStep={setStep} />;
    }
  };

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/nrf2025" element={<NRF25 />} />
          <Route path="/overlay" element={renderOverlay()} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
