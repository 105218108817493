import React, { useState } from 'react';
import '../../overlays/overNRF.css';
import coupons from '../../../../db/ccps.json'; // Importa o JSON com os cupons

function Overlay3({ setStep }) {
    const [cupom, setCupom] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
  
    const handleInputChange = (e) => {
      const input = e.target.value;
      setCupom(input);
      setIsLoading(true);
  
      // Exibe o círculo de carregamento por 1 segundo antes de verificar o cupom
      setTimeout(() => {
        setIsLoading(false);
  
        // Verificação do cupom após 1 segundo
        if (coupons.cumpons.includes(input)) {
          setError('');
          setTimeout(() => {
            setStep((prevStep) => prevStep + 1);
          }, 1000);
        } else {
          setError('Cupom inválido.');
        }
      }, 1000);
    };
  
    return (
      <section className="overlayBox3">
        <h2>Insira seu CUPOM</h2>
        <div className="form-group cupom">
          <input
            type="text"
            id="cupom"
            name="cupom"
            className="cupom"
            placeholder="SEU CUPOM AQUI"
            value={cupom}
            onChange={handleInputChange}
          />
          <div className="iconSVG">
          <svg width="30" height="30" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M31.4333 54.6665L40.9999 47.4915L50.3958 54.6665L46.8083 43.0498L56.3749 35.5332H44.7583L40.9999 23.9165L37.2416 35.5332H25.6249L35.0208 43.0498L31.4333 54.6665ZM13.6666 68.3332C11.7874 68.3332 10.1787 67.6641 8.84054 66.3259C7.50235 64.9877 6.83325 63.379 6.83325 61.4998V49.9686C6.83325 49.3422 7.03256 48.8012 7.43117 48.3457C7.82978 47.8901 8.34228 47.6054 8.96867 47.4915C10.3353 47.036 11.46 46.2103 12.3426 45.0144C13.2253 43.8186 13.6666 42.4804 13.6666 40.9998C13.6666 39.5193 13.2253 38.1811 12.3426 36.9853C11.46 35.7894 10.3353 34.9637 8.96867 34.5082C8.34228 34.3943 7.82978 34.1096 7.43117 33.654C7.03256 33.1984 6.83325 32.6575 6.83325 32.0311V20.4998C6.83325 18.6207 7.50235 17.012 8.84054 15.6738C10.1787 14.3356 11.7874 13.6665 13.6666 13.6665H68.3333C70.2124 13.6665 71.8211 14.3356 73.1593 15.6738C74.4975 17.012 75.1666 18.6207 75.1666 20.4998V32.0311C75.1666 32.6575 74.9673 33.1984 74.5687 33.654C74.1701 34.1096 73.6576 34.3943 73.0312 34.5082C71.6645 34.9637 70.5399 35.7894 69.6572 36.9853C68.7746 38.1811 68.3333 39.5193 68.3333 40.9998C68.3333 42.4804 68.7746 43.8186 69.6572 45.0144C70.5399 46.2103 71.6645 47.036 73.0312 47.4915C73.6576 47.6054 74.1701 47.8901 74.5687 48.3457C74.9673 48.8012 75.1666 49.3422 75.1666 49.9686V61.4998C75.1666 63.379 74.4975 64.9877 73.1593 66.3259C71.8211 67.6641 70.2124 68.3332 68.3333 68.3332H13.6666ZM13.6666 61.4998H68.3333V52.7873C66.2263 51.5346 64.5607 49.8689 63.3364 47.7905C62.1121 45.712 61.4999 43.4484 61.4999 40.9998C61.4999 38.5512 62.1121 36.2877 63.3364 34.2092C64.5607 32.1307 66.2263 30.4651 68.3333 29.2123V20.4998H13.6666V29.2123C15.7735 30.4651 17.4392 32.1307 18.6635 34.2092C19.8878 36.2877 20.4999 38.5512 20.4999 40.9998C20.4999 43.4484 19.8878 45.712 18.6635 47.7905C17.4392 49.8689 15.7735 51.5346 13.6666 52.7873V61.4998Z" fill="white" />
                    </svg>
          </div>
        </div>
        {isLoading && (
          <div className="loading-circle">
            {/* Circle Loading Animation */}
          </div>
        )}
        {error && !isLoading && <p style={{ color: 'red' }}>{error}</p>}
      </section>
    );
  }
  
  export default Overlay3;