import React from "react";
import '../../overlays/overNRF.css';


function Overlay1({ setStep }) {
    const handleNext = () => {
        setStep(2);
    };
    return (
        <section className="overlayBox">
            <h2>Inscrição NRF2025</h2>
            <form>
                <div className="form-group">
                    <label htmlFor="name">Nome</label>
                    <input type="text" id="name" name="name" placeholder="Digite seu nome" />
                </div>

                <div className="form-group">
                    <label htmlFor="email">E-mail</label>
                    <input type="email" id="email" name="email" placeholder="Digite seu e-mail" />
                </div>

                <div className="form-group">
                    <label htmlFor="phone">Telefone</label>
                    <input type="text" id="phone" name="phne" placeholder="Digite seu telefone" />
                </div>


                <div className="form-group">
                    <label htmlFor="empresa">Empresa</label>
                    <input type="text" id="empresa" name="empresa" placeholder="Digite o nome da empresa" />
                </div>

                <div className="form-group">
                    <label htmlFor="cargo">Cargo</label>
                    <input type="text" id="cargo" name="cargo" placeholder="Digite seu cargo" />
                </div>

                <button type="submit" onClick={handleNext}>Próximo</button>
            </form>
        </section>
    );
}

export default Overlay1;
