import React from "react";
import '../../overlays/overNRF.css'

function Overlay2({ setStep }) {
    const handlePayNow = () => {
        window.location.href = "https://www.asaas.com/c/18dsvo1sebtr5alt";
    };

    const handleCoupon = () => {
        setStep(3);
    };

    const handleContactSpecialist = () => {
        window.location.href = "https://api.whatsapp.com/send?phone=554788127141&text=Ol%C3%A1!%20Quero%20ir%20para%20a%20NRF%202025.";
    };

    return (
        <section className="overlayBox2">
            <h2>Inscrição NRF2025</h2>
            <div className="line">
                <div className="card" onClick={handlePayNow}>
                <svg width="50" height="50" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.0833 71.75C15.2042 71.75 13.5955 71.0809 12.2573 69.7427C10.9191 68.4045 10.25 66.7958 10.25 64.9167V17.0833C10.25 15.2042 10.9191 13.5955 12.2573 12.2573C13.5955 10.9191 15.2042 10.25 17.0833 10.25H64.9167C66.7958 10.25 68.4045 10.9191 69.7427 12.2573C71.0809 13.5955 71.75 15.2042 71.75 17.0833V25.625H64.9167V17.0833H17.0833V64.9167H64.9167V56.375H71.75V64.9167C71.75 66.7958 71.0809 68.4045 69.7427 69.7427C68.4045 71.0809 66.7958 71.75 64.9167 71.75H17.0833ZM44.4167 58.0833C42.5375 58.0833 40.9288 57.4142 39.5906 56.076C38.2524 54.7378 37.5833 53.1292 37.5833 51.25V30.75C37.5833 28.8708 38.2524 27.2622 39.5906 25.924C40.9288 24.5858 42.5375 23.9167 44.4167 23.9167H68.3333C70.2125 23.9167 71.8212 24.5858 73.1594 25.924C74.4976 27.2622 75.1667 28.8708 75.1667 30.75V51.25C75.1667 53.1292 74.4976 54.7378 73.1594 56.076C71.8212 57.4142 70.2125 58.0833 68.3333 58.0833H44.4167ZM68.3333 51.25V30.75H44.4167V51.25H68.3333ZM54.6667 46.125C56.0903 46.125 57.3003 45.6267 58.2969 44.6302C59.2934 43.6337 59.7917 42.4236 59.7917 41C59.7917 39.5764 59.2934 38.3663 58.2969 37.3698C57.3003 36.3733 56.0903 35.875 54.6667 35.875C53.2431 35.875 52.033 36.3733 51.0365 37.3698C50.0399 38.3663 49.5417 39.5764 49.5417 41C49.5417 42.4236 50.0399 43.6337 51.0365 44.6302C52.033 45.6267 53.2431 46.125 54.6667 46.125Z" fill="white" />
                    </svg>
                    <p>Pagar agora</p>
                </div>
                <div className="card" onClick={handleCoupon}>
                    <svg width="50" height="50" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M31.4333 54.6665L40.9999 47.4915L50.3958 54.6665L46.8083 43.0498L56.3749 35.5332H44.7583L40.9999 23.9165L37.2416 35.5332H25.6249L35.0208 43.0498L31.4333 54.6665ZM13.6666 68.3332C11.7874 68.3332 10.1787 67.6641 8.84054 66.3259C7.50235 64.9877 6.83325 63.379 6.83325 61.4998V49.9686C6.83325 49.3422 7.03256 48.8012 7.43117 48.3457C7.82978 47.8901 8.34228 47.6054 8.96867 47.4915C10.3353 47.036 11.46 46.2103 12.3426 45.0144C13.2253 43.8186 13.6666 42.4804 13.6666 40.9998C13.6666 39.5193 13.2253 38.1811 12.3426 36.9853C11.46 35.7894 10.3353 34.9637 8.96867 34.5082C8.34228 34.3943 7.82978 34.1096 7.43117 33.654C7.03256 33.1984 6.83325 32.6575 6.83325 32.0311V20.4998C6.83325 18.6207 7.50235 17.012 8.84054 15.6738C10.1787 14.3356 11.7874 13.6665 13.6666 13.6665H68.3333C70.2124 13.6665 71.8211 14.3356 73.1593 15.6738C74.4975 17.012 75.1666 18.6207 75.1666 20.4998V32.0311C75.1666 32.6575 74.9673 33.1984 74.5687 33.654C74.1701 34.1096 73.6576 34.3943 73.0312 34.5082C71.6645 34.9637 70.5399 35.7894 69.6572 36.9853C68.7746 38.1811 68.3333 39.5193 68.3333 40.9998C68.3333 42.4804 68.7746 43.8186 69.6572 45.0144C70.5399 46.2103 71.6645 47.036 73.0312 47.4915C73.6576 47.6054 74.1701 47.8901 74.5687 48.3457C74.9673 48.8012 75.1666 49.3422 75.1666 49.9686V61.4998C75.1666 63.379 74.4975 64.9877 73.1593 66.3259C71.8211 67.6641 70.2124 68.3332 68.3333 68.3332H13.6666ZM13.6666 61.4998H68.3333V52.7873C66.2263 51.5346 64.5607 49.8689 63.3364 47.7905C62.1121 45.712 61.4999 43.4484 61.4999 40.9998C61.4999 38.5512 62.1121 36.2877 63.3364 34.2092C64.5607 32.1307 66.2263 30.4651 68.3333 29.2123V20.4998H13.6666V29.2123C15.7735 30.4651 17.4392 32.1307 18.6635 34.2092C19.8878 36.2877 20.4999 38.5512 20.4999 40.9998C20.4999 43.4484 19.8878 45.712 18.6635 47.7905C17.4392 49.8689 15.7735 51.5346 13.6666 52.7873V61.4998Z" fill="white" />
                    </svg>
                    <p>Tenho um cupom</p>
                </div>
                <div className="card" onClick={handleContactSpecialist}>
                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M25.0002 24.9997C22.7085 24.9997 20.7467 24.1837 19.1147 22.5518C17.4828 20.9198 16.6668 18.958 16.6668 16.6663C16.6668 14.3747 17.4828 12.4129 19.1147 10.7809C20.7467 9.14898 22.7085 8.33301 25.0002 8.33301C27.2918 8.33301 29.2536 9.14898 30.8856 10.7809C32.5175 12.4129 33.3335 14.3747 33.3335 16.6663C33.3335 18.958 32.5175 20.9198 30.8856 22.5518C29.2536 24.1837 27.2918 24.9997 25.0002 24.9997ZM8.3335 41.6663V35.833C8.3335 34.6525 8.63732 33.5674 9.24495 32.5778C9.85259 31.5882 10.6599 30.833 11.6668 30.3122C13.8196 29.2358 16.0071 28.4285 18.2293 27.8903C20.4516 27.3521 22.7085 27.083 25.0002 27.083C27.2918 27.083 29.5488 27.3521 31.771 27.8903C33.9932 28.4285 36.1807 29.2358 38.3335 30.3122C39.3404 30.833 40.1477 31.5882 40.7554 32.5778C41.363 33.5674 41.6668 34.6525 41.6668 35.833V41.6663H8.3335ZM12.5002 37.4997H37.5002V35.833C37.5002 35.4511 37.4047 35.1038 37.2137 34.7913C37.0227 34.4788 36.771 34.2358 36.4585 34.0622C34.5835 33.1247 32.6911 32.4216 30.7814 31.9528C28.8717 31.4841 26.9446 31.2497 25.0002 31.2497C23.0557 31.2497 21.1286 31.4841 19.2189 31.9528C17.3092 32.4216 15.4168 33.1247 13.5418 34.0622C13.2293 34.2358 12.9776 34.4788 12.7866 34.7913C12.5956 35.1038 12.5002 35.4511 12.5002 35.833V37.4997ZM25.0002 20.833C26.146 20.833 27.1269 20.425 27.9429 19.6091C28.7588 18.7931 29.1668 17.8122 29.1668 16.6663C29.1668 15.5205 28.7588 14.5396 27.9429 13.7236C27.1269 12.9077 26.146 12.4997 25.0002 12.4997C23.8543 12.4997 22.8734 12.9077 22.0575 13.7236C21.2415 14.5396 20.8335 15.5205 20.8335 16.6663C20.8335 17.8122 21.2415 18.7931 22.0575 19.6091C22.8734 20.425 23.8543 20.833 25.0002 20.833Z" fill="white" />
                    </svg>
                    <p>Falar com o especialista</p>
                </div>
            </div>
        </section>
    )
}

export default Overlay2;
