import React, { useState, useEffect } from 'react';
import '../NRF25/nrf25.css';
import getLogo from '../../assets/getGlobal-W.png';
import video from '../../assets/luz.mp4';
import nrfImage from '../../assets/nrf-image.jpg'
import ticket1 from '../../assets/ticket-1.png'
import ticket2 from '../../assets/ticket-2.png'
import hotel from '../../assets/hotel-photo.jpg'
import feed1 from '../../assets/tulio.png'
import feed2 from '../../assets/arthur.png'
import pb1 from '../../assets/bold.png'
import pb2 from '../../assets/coffe.png'
import pb3 from '../../assets/talk.png'
import pb4 from '../../assets/ilha.png'
import thiago from '../../assets/thiago.png'
import dani from '../../assets/dani.png'
import renzo from '../../assets/renzo.jpg'

function NRF25() {
    const [overlayVisible, setOverlayVisible] = useState(false);

    useEffect(() => {
        // Atualize as meta tags com base na página NRF
        document.querySelector('meta[name="description"]').setAttribute('content', 'NRF 2025, a maior feira de varejo do mundo. Junte-se a delegação getGlobal e viage conosco para Nova York. Acesse as informações de ingresso e passagens abaixo.');
        document.title = 'NRF 2025 - A maior feira de varejo do mundo';
        // Outras meta tags (og:title, og:image, etc.) também podem ser atualizadas aqui

        if (overlayVisible) {
            const script = document.createElement('script');
            script.src = "https://d335luupugsy2.cloudfront.net/js/rdstation-forms/stable/rdstation-forms.min.js";
            script.onload = () => {
                new window.RDStationForms('inscricao-nrf25-ac0a8612bdd314966c84', 'UA-221291415-1').createForm();
            };
            document.body.appendChild(script);
        }
    }, [overlayVisible]);

    const handleClick = () => {
        setOverlayVisible(true);
    };

    const closeOverlay = () => {
        setOverlayVisible(false);
    };

    return (
        <section className='content'>
            {overlayVisible && (
                <div className="overlay">
                    <div className="overlay-content">
                        <a onClick={closeOverlay}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 6L6 18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M6 6L18 18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg></a>
                        <div role="main" id="inscricao-nrf25-ac0a8612bdd314966c84"></div>
                        <script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/rdstation-forms/stable/rdstation-forms.min.js"></script>
                        <script type="text/javascript">
                            new RDStationForms('inscricao-nrf25-ac0a8612bdd314966c84', 'UA-221291415-1').createForm();
                        </script>

                    </div>
                </div>
            )}
            <section className='header' content='foto-nova-york' alt='foto-nova-york' title='foto-nova-york'>
                <div className='header-title-box'>
                    <div className='line-header'>
                        <div className='sub-title-1'><strong>A MAIOR FEIRA DE VAREJO DO MUNDO</strong></div>
                        <img src={getLogo} content='logo-getGlobal' alt='logo-getGlobal' title='logo-getGlobal' />
                    </div>
                    <div className='title-box'>
                        <h1>Junte-se a <strong>delegação internacional</strong> na missão NRF 2025.</h1>
                    </div>
                    <div className='line-bottom'>
                        <div className='sub-title-2'><p>Com profissionais renomados e diversos empresários junte-se a nós em mais uma missão NRF.</p></div>
                        <div className='cta' onClick={handleClick}><p>Garantir meu acesso</p></div>
                    </div>
                </div>
            </section>

            <section className='getabout'>
                <div className='title-about'><h2>Sobre o <strong>getGlobal®️</strong></h2></div>
                <div className='box-about'>
                    <div className='content-item'>
                        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M25 45.8334C36.506 45.8334 45.8334 36.506 45.8334 25.0001C45.8334 13.4941 36.506 4.16675 25 4.16675C13.4941 4.16675 4.16669 13.4941 4.16669 25.0001C4.16669 36.506 13.4941 45.8334 25 45.8334Z" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M33.8334 16.1667L29.4167 29.4167L16.1667 33.8334L20.5834 20.5834L33.8334 16.1667Z" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <p className='text'>Divisão de experiências educacionais e imersões internacionais da Father&Company, uma das principais e maiores empresas de comércio exterior do Brasil. Pelo terceiro ano consecutivo temos o prazer de realizar a Missão getGlobal NRF 2025.</p>
                    </div>
                    <div className='content-item'>
                        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M25 45.8334C36.5059 45.8334 45.8333 36.506 45.8333 25.0001C45.8333 13.4941 36.5059 4.16675 25 4.16675C13.4941 4.16675 4.16667 13.4941 4.16667 25.0001C4.16667 36.506 13.4941 45.8334 25 45.8334Z" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M4.16667 25H45.8333" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M25 4.16675C30.211 9.87165 33.1724 17.2752 33.3333 25.0001C33.1724 32.725 30.211 40.1285 25 45.8334C19.789 40.1285 16.8276 32.725 16.6667 25.0001C16.8276 17.2752 19.789 9.87165 25 4.16675Z" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <p className='text'>Este evento anual oferece uma oportunidade única para nossos participantes se conectarem com líderes globais, explorarem as mais recentes tendências de varejo e inovação, e obterem insights estratégicos que impulsionam o sucesso no mercado nacional e internacional.</p>
                    </div>
                </div>
                <div className='cta plus center top-off' onClick={handleClick}><p>Juntar-me a delegação getGlobal®️﻿</p></div>
            </section>

            <section className='nrfabout'>
                <div className='box-nrf center'>
                    <div className='img-nrf'></div>
                    <div className='text-nrf center'>
                        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M25 4.1665L31.4375 17.2082L45.8333 19.3123L35.4167 29.4582L37.875 43.7915L25 37.0207L12.125 43.7915L14.5833 29.4582L4.16666 19.3123L18.5625 17.2082L25 4.1665Z" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <h2>O que é a NRF?</h2>
                        <p className='text'>A NRF é consagrada como o maior evento de varejo e comportamento, a parada obrigatória de empresários e líderes do mercado.

                            Realizar imersões internacionais é primordial para ampliar nossa percepção de mundo e direcionar melhor nossos negócios. E nada mais especial que a cidade de Nova York para ver in loco o nascimento das próximas tendências do varejo mundial, no maior evento de varejo do mundo, a NRF. Além disso, respirar a atmosfera e o que as grandes marcas estão realizando para se destacarem nos negócios, bem como, entender as tendências de consumo e comportamento que guiam negócios!</p>
                    </div>
                </div>
                <div className='line-itens center'>
                    <div className='item first-margin'>
                        <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.16669 16.9585C9.84657 16.9585 13.3757 18.4203 15.9778 21.0224C18.5799 23.6245 20.0417 27.1536 20.0417 30.8335" stroke="#B2B2B2" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M6.16669 6.1665C12.7087 6.1665 18.9828 8.76531 23.6087 13.3912C28.2346 18.0171 30.8334 24.2912 30.8334 30.8332" stroke="#B2B2B2" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M7.70835 30.8333C8.55979 30.8333 9.25002 30.1431 9.25002 29.2917C9.25002 28.4402 8.55979 27.75 7.70835 27.75C6.85691 27.75 6.16669 28.4402 6.16669 29.2917C6.16669 30.1431 6.85691 30.8333 7.70835 30.8333Z" stroke="#B2B2B2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <h4>Networking</h4>
                        <p className='text-2'>O evento é uma oportunidade
                            única para conhecer profissionais de diferentes partes do mundo.
                        </p>
                    </div>
                    <div className='item'>
                        <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M35.4584 9.25L20.8125 23.8958L13.1042 16.1875L1.54169 27.75" stroke="#B2B2B2" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M26.2083 9.25H35.4583V18.5" stroke="#B2B2B2" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                        <h4>Carreira</h4>
                        <p className='text-2'>A NRF proporciona uma base de conhecimento e networking essencial para quem quer se manter atualizado e relevante no mundo dos negócios.
                        </p>
                    </div>
                    <div className='item'>
                        <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 22C23.5228 22 28 17.7467 28 12.5C28 7.25329 23.5228 3 18 3C12.4772 3 8 7.25329 8 12.5C8 17.7467 12.4772 22 18 22Z" stroke="#B2B2B2" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M12.694 23.0132L11 35L18 31.0526L25 35L23.306 23" stroke="#B2B2B2" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <h4>Aprenda com os
                            melhores</h4>
                        <p className='text-2'>Mais de 300 palestras com nomes de destaques em suas áreas.
                        </p>
                    </div>
                    <div className='item last-margin'>
                        <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.5 33.9168C27.0144 33.9168 33.9167 27.0146 33.9167 18.5002C33.9167 9.98577 27.0144 3.0835 18.5 3.0835C9.98561 3.0835 3.08333 9.98577 3.08333 18.5002C3.08333 27.0146 9.98561 33.9168 18.5 33.9168Z" stroke="#B2B2B2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M18.5 27.75C23.6086 27.75 27.75 23.6086 27.75 18.5C27.75 13.3914 23.6086 9.25 18.5 9.25C13.3914 9.25 9.25 13.3914 9.25 18.5C9.25 23.6086 13.3914 27.75 18.5 27.75Z" stroke="#B2B2B2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M18.5 21.5832C20.2029 21.5832 21.5833 20.2027 21.5833 18.4998C21.5833 16.797 20.2029 15.4165 18.5 15.4165C16.7971 15.4165 15.4167 16.797 15.4167 18.4998C15.4167 20.2027 16.7971 21.5832 18.5 21.5832Z" stroke="#B2B2B2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <h4>Visão estratégica</h4>
                        <p className='text-2'>A NRF é a maior conferência e feira mundial sobre varejo.
                            A participação permite conhecer novidades, tendências e inovação em primeira mão.
                        </p>
                    </div>
                </div>
            </section>

            <section className='event'>
                <div className='event-title'><h3>Presença confirmada
                    <strong> evento exclusivo</strong>  getGlobal®</h3></div>
                <div className='renzo'>
                    <img src={renzo}></img> <p><strong>Renzo Gracie</strong> é uma figura icônica no mundo do jiu-jitsu e MMA, conhecido por suas habilidades técnicas excepcionais e sua mentalidade vencedora. Seus livros e academias têm influenciado gerações de praticantes, consolidando seu legado na história das artes marciais. Renzo continua a inspirar com sua filosofia de resiliência, determinação e compromisso inabalável com a excelência. Renzo será um dos palestrantes no evento exlusivo do getGlobal em Nova Iorque.</p>
                </div>
                <div className='cta plus center top-on' onClick={handleClick}><p>Garantir meu acesso agora</p></div>

            </section>

            <section className='agend'>
                <div className='agend-title top-on center'><h3>Agenda delegação
                    <strong> getGlobal®️﻿ 2025</strong></h3></div>
                <div className='box-agend-center'>
                    <div className='block right-block'><div className='point'></div><h4>08/01</h4> <small>Saída do Brasil</small></div>
                    <div className='block left-block'><div className='point'></div><h4>09/01</h4> <small>Chegada e warm up no consulado</small></div>
                    <div className='block right-block'><div className='point'></div><h4>10/01</h4> <small>Visitas técnicas</small></div>
                    <div className='block left-block'><div className='point'></div><h4>11/01</h4> <small>Visitas técnicas + Opening party NRF</small></div>
                    <div className='block right-block'><div className='point'></div><h4>12/01</h4> <small>Dia 1 NRF + wrap up grupo</small></div>
                    <div className='block left-block'><div className='point'></div><h4>13/01</h4> <small>Dia 2 NRF + wrap up grupo</small></div>
                    <div className='block right-block'><div className='point'></div><h4>14/01</h4> <small>Dia 3 NRF + evento exclusivo</small></div>
                    <div className='block left-block'><div className='point'></div><h4>15/01</h4> <small>Trend walk</small></div>
                    <div className='block right-block'><div className='point'></div><h4>16/01</h4> <small>Dia livre</small></div>
                    <div className='block left-block'><div className='point'></div><h4>17/01</h4> <small>Manhã livre + Retorno ao Brasil</small></div>
                    <div className='block right-block'><div className='point'></div><h4>18/01</h4> <small>Chegada ao Brasil</small></div>
                </div>
                <div className='attention top-on'><h5>*Ajustes podem ser necessários com base nos agendamentos.</h5></div>
                <div className='box-tags'>
                    <div className='line-tag'>
                        <div className='tag pad-2'><p>SEGURANÇA E CONFORTO</p></div>
                        <div className='tag'><p>NETWORKING</p></div>
                        <div className='tag'><p>EVENTOS EXCLUSIVOS</p></div>
                    </div>
                    <div className='line-tag'>
                        <div className='tag pad'><p>VISITAS GUIADAS EM NOVA IORQUE</p></div>
                        <div className='tag'><p>ACESSOS EXLUSIVOS</p></div>
                    </div>
                </div>
            </section>
            <section className='pricing'>
                <div className='box-pricing'><div className='title-pricing'><h2>Junte-se a diversos empresários na <strong> missão internacional getGlobal®</strong></h2></div>
                    <div className='card-pricing'>
                        <img src={nrfImage}></img>
                        <div className='princing-box-text'>
                            <div className='title-card-princing'><p>O valor é de:</p>
                                <h4>R$ 15.000,00<strong>à vista.</strong></h4></div>
                            <div className='box-itens'>

                                <div className='line-check'>
                                    <svg width="29" height="25" viewBox="0 0 29 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M24.1667 6.25L10.875 17.7083L4.83334 12.5" stroke="#3AF77B" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" /></svg>
                                    <p>Ingresso para entrada NRF</p>
                                </div>

                                <div className='line-check'>
                                    <svg width="29" height="25" viewBox="0 0 29 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M24.1667 6.25L10.875 17.7083L4.83334 12.5" stroke="#3AF77B" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" /></svg>
                                    <p>Acompanhamento durante toda a viagem e feira</p>
                                </div>

                                <div className='line-check'>
                                    <svg width="29" height="25" viewBox="0 0 29 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M24.1667 6.25L10.875 17.7083L4.83334 12.5" stroke="#3AF77B" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" /></svg>
                                    <p>Roteiro de visitas técnicas guiadas na cidade de Nova Iorque</p>
                                </div>

                                <div className='line-check'>
                                    <svg width="29" height="25" viewBox="0 0 29 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M24.1667 6.25L10.875 17.7083L4.83334 12.5" stroke="#3AF77B" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" /></svg>
                                    <p>Roteiro com visitas sugeridas</p>
                                </div>

                                <div className='line-check'>
                                    <svg width="29" height="25" viewBox="0 0 29 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M24.1667 6.25L10.875 17.7083L4.83334 12.5" stroke="#3AF77B" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" /></svg>
                                    <p>Evento especial</p>
                                </div>

                                <div className='line-check'>
                                    <svg width="29" height="25" viewBox="0 0 29 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M24.1667 6.25L10.875 17.7083L4.83334 12.5" stroke="#3AF77B" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" /></svg>
                                    <p>Networking com nossos consultores e empresários participantes</p>
                                </div>

                                <div className='line-check'>
                                    <svg width="29" height="25" viewBox="0 0 29 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M24.1667 6.25L10.875 17.7083L4.83334 12.5" stroke="#3AF77B" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" /></svg>
                                    <p>Suporte para compra de passagens e hospedagem com nosso parceiro</p>
                                </div>

                            </div>
                            <div className='cta center' onClick={handleClick}><p>Garantir meu acesso agora</p></div>
                        </div>
                    </div></div>
            </section>

            <section className='sugests'>
                <div className='title-airTicket'><svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="mask0_81_41" maskUnits="userSpaceOnUse" x="0" y="0" width="54" height="54">
                        <rect width="54" height="54" fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0_81_41)">
                        <path d="M6.75 47.2477V42.7477H47.25V47.2477H6.75ZM10.6875 35.9977L2.25 21.9352L7.65 20.4727L13.95 25.7602L21.825 23.6789L10.1813 8.15391L16.7063 6.41016L33.525 20.5289L43.0875 17.9414C44.2875 17.6039 45.4219 17.7445 46.4906 18.3633C47.5594 18.982 48.2625 19.8914 48.6 21.0914C48.9375 22.2914 48.7969 23.4258 48.1781 24.4945C47.5594 25.5633 46.65 26.2664 45.45 26.6039L10.6875 35.9977Z" fill="#B2B2B2" />
                    </g>
                </svg><h2>Passagens sugeridas</h2></div>
                <img className='ticket' src={ticket1}></img>
                <img className='ticket' src={ticket2}></img>
                <div className='attention'><h5>*Ajustes podem ser necessários com base em novas cotações.</h5></div>
                <a className='cta center plus more' target='blank' href='https://api.whatsapp.com/send?phone=554797244444&text=Olá%2C%20tenho%20interesse%20na%20NRF2025!'><p>Conferir passagens completas com ilhaTUR</p></a>
                <div className='title-airTicket'>
                    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <mask id="mask0_81_145" maskUnits="userSpaceOnUse" x="0" y="0" width="54" height="54">
                            <rect width="54" height="54" fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_81_145)">
                            <path d="M4.5 42.75V29.25C4.5 28.2375 4.70625 27.3188 5.11875 26.4938C5.53125 25.6687 6.075 24.9375 6.75 24.3V18C6.75 16.125 7.40625 14.5312 8.71875 13.2188C10.0312 11.9062 11.625 11.25 13.5 11.25H22.5C23.3625 11.25 24.1687 11.4094 24.9187 11.7281C25.6687 12.0469 26.3625 12.4875 27 13.05C27.6375 12.4875 28.3313 12.0469 29.0813 11.7281C29.8313 11.4094 30.6375 11.25 31.5 11.25H40.5C42.375 11.25 43.9688 11.9062 45.2812 13.2188C46.5938 14.5312 47.25 16.125 47.25 18V24.3C47.925 24.9375 48.4688 25.6687 48.8813 26.4938C49.2938 27.3188 49.5 28.2375 49.5 29.25V42.75H45V38.25H9V42.75H4.5ZM29.25 22.5H42.75V18C42.75 17.3625 42.5344 16.8281 42.1031 16.3969C41.6719 15.9656 41.1375 15.75 40.5 15.75H31.5C30.8625 15.75 30.3281 15.9656 29.8969 16.3969C29.4656 16.8281 29.25 17.3625 29.25 18V22.5ZM11.25 22.5H24.75V18C24.75 17.3625 24.5344 16.8281 24.1031 16.3969C23.6719 15.9656 23.1375 15.75 22.5 15.75H13.5C12.8625 15.75 12.3281 15.9656 11.8969 16.3969C11.4656 16.8281 11.25 17.3625 11.25 18V22.5ZM9 33.75H45V29.25C45 28.6125 44.7844 28.0781 44.3531 27.6469C43.9219 27.2156 43.3875 27 42.75 27H11.25C10.6125 27 10.0781 27.2156 9.64688 27.6469C9.21563 28.0781 9 28.6125 9 29.25V33.75Z" fill="#B2B2B2" />
                        </g>
                    </svg>

                    <h2>Hospedagem sugerida</h2></div>
                <div className='hosped'>
                    <img src={hotel}></img>
                    <div className='hosped-content'><h3>FAIRFIELD INN & SUITES NEW YORK MANHATTAN</h3><p>338 West 36th Street, New York, NY, 10018</p>
                        <div className='line-svg'><svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M33.75 15H35.625C37.6141 15 39.5218 15.7902 40.9283 17.1967C42.3348 18.6032 43.125 20.5109 43.125 22.5C43.125 24.4891 42.3348 26.3968 40.9283 27.8033C39.5218 29.2098 37.6141 30 35.625 30H33.75" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M3.75 15H33.75V31.875C33.75 33.8641 32.9598 35.7718 31.5533 37.1783C30.1468 38.5848 28.2391 39.375 26.25 39.375H11.25C9.26088 39.375 7.35322 38.5848 5.9467 37.1783C4.54018 35.7718 3.75 33.8641 3.75 31.875V15Z" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M11.25 1.875V7.5" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M18.75 1.875V7.5" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M26.25 1.875V7.5" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                        </svg> <p>Café da manhã incluso</p></div>
                        <div className='room-colum'>
                            <div className='room'>
                                <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="mask0_81_145" maskUnits="userSpaceOnUse" x="0" y="0" width="54" height="54">
                                        <rect width="54" height="54" fill="white" />
                                    </mask>
                                    <g mask="url(#mask0_81_145)">
                                        <path d="M4.5 42.75V29.25C4.5 28.2375 4.70625 27.3188 5.11875 26.4938C5.53125 25.6687 6.075 24.9375 6.75 24.3V18C6.75 16.125 7.40625 14.5312 8.71875 13.2188C10.0312 11.9062 11.625 11.25 13.5 11.25H22.5C23.3625 11.25 24.1687 11.4094 24.9187 11.7281C25.6687 12.0469 26.3625 12.4875 27 13.05C27.6375 12.4875 28.3313 12.0469 29.0813 11.7281C29.8313 11.4094 30.6375 11.25 31.5 11.25H40.5C42.375 11.25 43.9688 11.9062 45.2812 13.2188C46.5938 14.5312 47.25 16.125 47.25 18V24.3C47.925 24.9375 48.4688 25.6687 48.8813 26.4938C49.2938 27.3188 49.5 28.2375 49.5 29.25V42.75H45V38.25H9V42.75H4.5ZM29.25 22.5H42.75V18C42.75 17.3625 42.5344 16.8281 42.1031 16.3969C41.6719 15.9656 41.1375 15.75 40.5 15.75H31.5C30.8625 15.75 30.3281 15.9656 29.8969 16.3969C29.4656 16.8281 29.25 17.3625 29.25 18V22.5ZM11.25 22.5H24.75V18C24.75 17.3625 24.5344 16.8281 24.1031 16.3969C23.6719 15.9656 23.1375 15.75 22.5 15.75H13.5C12.8625 15.75 12.3281 15.9656 11.8969 16.3969C11.4656 16.8281 11.25 17.3625 11.25 18V22.5ZM9 33.75H45V29.25C45 28.6125 44.7844 28.0781 44.3531 27.6469C43.9219 27.2156 43.3875 27 42.75 27H11.25C10.6125 27 10.0781 27.2156 9.64688 27.6469C9.21563 28.0781 9 28.6125 9 29.25V33.75Z" fill="white" />
                                    </g>
                                </svg>
                                <h4>Quarto, 1 cama King</h4>
                                <h3>R$ 14.080 para 8 noites</h3>
                                <p>Reembolso total até 6 de janeiro</p>
                            </div>
                            <div className='room'>
                                <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="mask0_81_145" maskUnits="userSpaceOnUse" x="0" y="0" width="54" height="54">
                                        <rect width="54" height="54" fill="white" />
                                    </mask>
                                    <g mask="url(#mask0_81_145)">
                                        <path d="M4.5 42.75V29.25C4.5 28.2375 4.70625 27.3188 5.11875 26.4938C5.53125 25.6687 6.075 24.9375 6.75 24.3V18C6.75 16.125 7.40625 14.5312 8.71875 13.2188C10.0312 11.9062 11.625 11.25 13.5 11.25H22.5C23.3625 11.25 24.1687 11.4094 24.9187 11.7281C25.6687 12.0469 26.3625 12.4875 27 13.05C27.6375 12.4875 28.3313 12.0469 29.0813 11.7281C29.8313 11.4094 30.6375 11.25 31.5 11.25H40.5C42.375 11.25 43.9688 11.9062 45.2812 13.2188C46.5938 14.5312 47.25 16.125 47.25 18V24.3C47.925 24.9375 48.4688 25.6687 48.8813 26.4938C49.2938 27.3188 49.5 28.2375 49.5 29.25V42.75H45V38.25H9V42.75H4.5ZM29.25 22.5H42.75V18C42.75 17.3625 42.5344 16.8281 42.1031 16.3969C41.6719 15.9656 41.1375 15.75 40.5 15.75H31.5C30.8625 15.75 30.3281 15.9656 29.8969 16.3969C29.4656 16.8281 29.25 17.3625 29.25 18V22.5ZM11.25 22.5H24.75V18C24.75 17.3625 24.5344 16.8281 24.1031 16.3969C23.6719 15.9656 23.1375 15.75 22.5 15.75H13.5C12.8625 15.75 12.3281 15.9656 11.8969 16.3969C11.4656 16.8281 11.25 17.3625 11.25 18V22.5ZM9 33.75H45V29.25C45 28.6125 44.7844 28.0781 44.3531 27.6469C43.9219 27.2156 43.3875 27 42.75 27H11.25C10.6125 27 10.0781 27.2156 9.64688 27.6469C9.21563 28.0781 9 28.6125 9 29.25V33.75Z" fill="white" />
                                    </g>
                                </svg>

                                <h4>Quarto, 2 camas casal</h4>
                                <h3>R$ 15.525 para 8 noites</h3>
                                <p>Reembolso total até 6 de janeiro</p>
                            </div>
                        </div>
                    </div>
                </div>
                <a className='cta center plus more' target='blank' href='https://api.whatsapp.com/send?phone=554797244444&text=Olá%2C%20tenho%20interesse%20na%20NRF2025!'><p>Conferir hospedagem completa com ilhaTUR</p></a>
            </section>
            <section className='feedback'>
                <div className='feed-title'><h4>Opinião de quem já viveu essa experiência com a gente.
                </h4></div>
                <div className='box-feed'>
                    <div className='feed'>
                        <img src={feed1}></img>
                        <h4>Túlio, Rovitex</h4>
                        <p>Equipe atenciosa e sempre disponível para tirar dúvidas ou fazer sugestões. Parabéns pela dedicação e espero encontrarmos no ano que vem novamente. Sucesso pessoal! Só elogios.</p>
                    </div>
                    <div className='feed'>
                        <img src={feed2}></img>
                        <h4>Arthur, Beagle</h4>
                        <p>Só tenho a agradecer pela oportunidade de ir a NRF com vocês!

                            Foi sensacional!</p>
                    </div>
                </div>
            </section>

            <section className='esp'>
                <div className='title-esp'><h3>Nossos especialistas</h3></div>
                <div className='line-esp'>
                    <img src={thiago}></img>
                    <img src={dani}></img>
                </div>
                <div className='cta center' onClick={handleClick}><p>Garantir meu acesso agora</p></div>
            </section>
            <section className='publi'>
                <div className='line-publi'>
                    <img src={pb1}></img>
                    <img src={pb2} className='sup'></img>
                    <img src={pb3}></img>
                    <img src={pb4}></img>

                </div>
            </section>
        </section>
    );
}

export default NRF25;
